<template>
  <div
    v-if="hasPageAccess(pageName)"
    id="reseller-profile"
  >
    <b-form
      class="form-container"
      @submit.prevent
    >
      <FormProgressBar :progress="progress()" />
      <b-btn
        aria-label="back"
        variant="link"
        class="back-button"
        @click="clickBack"
      >
        <i class="material-icons">arrow_back</i>
      </b-btn>
      <!-- ERRORS -->
      <FormErrors />
      <div class="form">
        <p
          ref="desc"
          tabindex="0"
          class="profile-description"
        >
          {{ description }}
        </p>

        <br>

        <!-- EDIT EMAIL/PHONE/NAME -->
        <!-- <b-form-group
          v-if="type === 'email'"
          id="user-profile-edit-group"
          :invalid-feedback="invalidFeedback"
          :state="state">
          <div v-if="type === 'name'">
            <FormGroup
              for="firstName"
              label="First Name*">
              <FormInput
                bind-id="firstName"
                get="firstName"
                set="UPDATE_FIRST_NAME"/>
            </FormGroup>
            <FormGroup
              for="lastName"
              label="Last Name">
              <FormInput
                bind-id="lastName"
                get="lastName"
                set="UPDATE_LAST_NAME"/>
            </FormGroup>
          </div>
          <div
            v-if="type === 'phone'"
            class="d-flex justify-content-between">
            <FormGroup
              for="countryCode"
              class="w-25-35">
              <FormSelect
                :options="countryCodeOptions"
                bind-id="countryCode"
                default="+1"
                get="countryCode"
                set="UPDATE_COUNTRY_CODE"/>
            </FormGroup>
            <FormGroup
              for="phoneNumber"
              class="w-75-65">
              <FormInput
                type="tel"
                bind-id="phoneNumber"
                get="phoneNumber"
                set="UPDATE_PHONE_NUMBER"
                placeholder="Mobile Number"/>
            </FormGroup>
          </div>
          <FormGroup for="email">
            <b-form-input
              v-if="type === 'email'"
              id="user-profile-edit-email"
              :state="state"
              :readonly="disabled"
              v-model="email"
              invalid-feedback="Error: This email is invalid"
              type="email"
              placeholder="Email Address"
              class="form-input-field"/>
          </FormGroup>
        </b-form-group> -->
        <!-- EDIT BILLING INFO AND PAYMENT METHOD -->
        <div
          v-if="type === 'billing'"
          id="profile-edit-payment-container"
        >
          <script2 src="https://js.recurly.com/v4/recurly.js" />
          <script2 src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
          <!-- eslint-disable -->
          <script2>
            $(document).ready(function () {
              recurly.configure({
                publicKey: '{{publicKey}}',
              });
            });

            recurly.on('field:submit', function (event) {
              event.preventDefault();
            });

            $('form').on('submit', function (event) {
              event.preventDefault();

              var form = this;
              const paymentType = $('#paymentType').val();
              if (paymentType === 'CC') {
                recurly.token(form, function (err, token) {
                  if (err) {
                    console.log(err);
                    alert(err.message + ' Please make sure your credit card number and expiration date are correct.');
                  } else {
                    $('#token').val(token.id);
                    $('#billingButton').click();
                  }
                });
              } else if (paymentType === 'ACH') {
                recurly.bankAccount.token(form, function (err, token) {
                  if (err) {
                    console.log(err);
                    alert(err.message + ' Please make sure your routing and account number are correct.');
                  } else {
                    $('#token').val(token.id);
                    $('#billingButton').click();
                  }
                });
              } else {
                console.error('error no payment type selected');
                alert('Please choose a payment type.');
              }
            });

          </script2>
          <!-- eslint-enable -->
          <form
            :action="`/api/account/${accountCode}`"
            method="put"
          >
            <!-- BILLING INFORMATION -->
            <h2 class="profile-heading">
              BILLING INFORMATION
            </h2>
            <FormGroup
              for="address1"
              label="Address"
              class="w-100"
            >
              <FormInput
                :state="null"
                name="address1"
                bind-id="address1"
                data-recurly="address1"
                invalid-feedback="Address Required."
                invalid-feedback-id="address1-invalid-feedback"
                get="address1"
                set="UPDATE_ADDRESS1"
                required
              />
            </FormGroup>
            <div class="d-flex justify-content-between">
              <FormGroup
                for="country"
                label="Country"
                class="w-50"
              >
                <FormSelect
                  :options="countryOptions"
                  :state="null"
                  name="country"
                  bind-id="country"
                  data-recurly="country"
                  invalid-feedback="Country Required."
                  invalid-feedback-id="country-invalid-feedback"
                  get="country"
                  set="UPDATE_COUNTRY"
                  required
                />
              </FormGroup>
              <FormGroup
                for="state"
                label="State/Province/Region"
                class="w-50"
              >
                <FormSelect
                  :options="stateOptions"
                  :state="null"
                  name="state"
                  bind-id="state"
                  data-recurly="state"
                  invalid-feedback="State Required."
                  invalid-feedback-id="state-invalid-feedback"
                  get="state"
                  set="UPDATE_STATE"
                  required
                />
              </FormGroup>
            </div>
            <div class="d-flex justify-content-between">
              <FormGroup
                for="city"
                label="City"
                class="w-50"
              >
                <FormInput
                  :state="null"
                  name="city"
                  bind-id="city"
                  data-recurly="city"
                  invalid-feedback="City Required."
                  invalid-feedback-id="city-invalid-feedback"
                  get="city"
                  set="UPDATE_CITY"
                  required
                />
              </FormGroup>
              <FormGroup
                for="zip"
                label="Zip or Postal Code"
                class="w-50"
              >
                <FormInput
                  :state="null"
                  name="zip"
                  bind-id="zip"
                  data-recurly="postal_code"
                  invalid-feedback="Zip or Postal Code Required."
                  invalid-feedback-id="zip-invalid-feedback"
                  get="zip"
                  set="UPDATE_ZIP"
                  required
                />
              </FormGroup>
            </div>
            <br>
            <!-- PAYMENT METHOD -->
            <h2 class="profile-heading">
              PAYMENT METHOD
            </h2>
            <FormGroup
              for="paymentType"
              label="Payment Type"
              class="w-100"
            >
              <FormSelect
                id="paymentType"
                :required="true"
                :options="paymentTypeOptions()"
                name="paymentType"
                bind-id="paymentType"
                get="paymentType"
                set="UPDATE_PAYMENT_TYPE"
              />
            </FormGroup>
            <!-- CREDIT CARD -->
            <div
              v-show="paymentType === 'CC'"
              id="cc-container"
            >
              <div class="d-flex justify-content-between">
                <FormGroup
                  for="firstName"
                  label="First Name"
                  class="w-50"
                >
                  <FormInput
                    :state="null"
                    :required="paymentType === 'CC'"
                    name="firstName"
                    bind-id="firstName"
                    data-recurly="first_name"
                    invalid-feedback="First Name Required."
                    invalid-feedback-id="first-name-invalid-feedback"
                    get="ccFirstName"
                    set="UPDATE_CC_FIRST_NAME"
                  />
                </FormGroup>
                <FormGroup
                  for="lastName"
                  label="Last Name"
                  class="w-50"
                >
                  <FormInput
                    :state="null"
                    :required="paymentType === 'CC'"
                    name="lastName"
                    bind-id="lastName"
                    data-recurly="last_name"
                    invalid-feedback="Last Name Required."
                    invalid-feedback-id="last-name-invalid-feedback"
                    get="ccLastName"
                    set="UPDATE_CC_LAST_NAME"
                  />
                </FormGroup>
              </div>
              <FormGroup
                for="cc"
                label="Card Number"
                class="w-100"
              >
                <div
                  class="recurly-field"
                  role="none"
                  data-recurly="number"
                />
              </FormGroup>
              <div class="d-flex justify-content-between">
                <FormGroup
                  for="expiryMonth"
                  label="Expiration Month (MM)"
                  class="w-50"
                >
                  <div
                    class="recurly-field"
                    role="none"
                    data-recurly="month"
                  />
                </FormGroup>
                <FormGroup
                  for="expiryYear"
                  label="Expiration Year (YYYY)"
                  class="w-50"
                >
                  <div
                    class="recurly-field"
                    role="none"
                    data-recurly="year"
                  />
                </FormGroup>
              </div>
              <FormGroup
                for="cvv"
                label="CVV"
                class="w-50"
              >
                <FormInput
                  :state="null"
                  name="cvv"
                  bind-id="cvv"
                  data-recurly="cvv"
                  invalid-feedback="CVV Required."
                  invalid-feedback-id="cvv-invalid-feedback"
                  get="cvv"
                  set="UPDATE_CVV"
                  required
                />
              </FormGroup>
            </div>
            <!-- ACH -->
            <div
              v-if="paymentType === 'ACH'"
              id="ach-container"
            >
              <FormGroup
                for="achName"
                label="Name On Account"
                class="w-10"
              >
                <FormInput
                  :state="null"
                  :required="paymentType === 'ACH'"
                  name="achName"
                  bind-id="achName"
                  data-recurly="name_on_account"
                  invalid-feedback="Name On Account Required."
                  invalid-feedback-id="ach-name-invalid-feedback"
                  get="achFullName"
                  set="UPDATE_ACH_FULL_NAME"
                />
              </FormGroup>
              <FormGroup
                for="achRoutingNumber"
                label="Routing Number"
                class="w-100"
              >
                <FormInput
                  :state="null"
                  :required="paymentType === 'ACH'"
                  name="achRoutingNumber"
                  bind-id="achRoutingNumber"
                  data-recurly="routing_number"
                  invalid-feedback="Routing Number Required."
                  invalid-feedback-id="ach-routing-number-invalid-feedback"
                  get="achRoutingNumber"
                  set="UPDATE_ACH_ROUTING_NUMBER"
                />
              </FormGroup>
              <FormGroup
                for="achAccountNumber"
                label="Account Number"
                class="w-100"
              >
                <FormInput
                  :state="null"
                  :required="paymentType === 'ACH'"
                  name="achAccountNumber"
                  bind-id="achAccountNumber"
                  data-recurly="account_number"
                  invalid-feedback="Account Number Required."
                  invalid-feedback-id="ach-account-number-invalid-feedback"
                  get="achAccountNumber"
                  set="UPDATE_ACH_ACCOUNT_NUMBER"
                />
              </FormGroup>
              <FormGroup
                for="achAccountNumberConfirmation"
                label="Account Number Confirmation"
                class="w-100"
              >
                <FormInput
                  :state="null"
                  :required="paymentType === 'ACH'"
                  name="achAccountNumberConfirmation"
                  bind-id="achAccountNumberConfirmation"
                  data-recurly="account_number_confirmation"
                  invalid-feedback="Account Number Confirmation Required."
                  invalid-feedback-id="ach-account-number-confirmation-invalid-feedback"
                  get="achAccountNumberConfirmation"
                  set="UPDATE_ACH_ACCOUNT_NUMBER_CONFIRMATION"
                />
              </FormGroup>
              <FormGroup
                for="achAccountType"
                label="Bank Account Type"
                class="w-100"
              >
                <FormSelect
                  :options="achAccountTypeOptions()"
                  :required="paymentType === 'ACH'"
                  name="achAccountType"
                  bind-id="achAccountType"
                  data-recurly="account_type"
                  get="achAccountType"
                  set="UPDATE_ACH_ACCOUNT_TYPE"
                />
              </FormGroup>
            </div>
            <input
              id="token"
              ref="token"
              type="hidden"
              name="recurly-token"
              data-recurly="token"
            >
            <b-btn
              :disabled="disabled"
              type="submit"
              class="signup-buttons w-100"
              size="lg"
              variant="primary"
            >
              <span v-if="disabled">
                <b-spinner label="processing" />
              </span>
              <span v-else>{{ buttonText }}</span>
            </b-btn>
            <b-btn
              id="billingButton"
              hidden
              @click="clickUpdate"
            />
          </form>
        </div>
        <!-- submission -->
        <b-btn
          v-if="type !== 'billing'"
          :disabled="disabled"
          type="submit"
          class="signup-buttons w-100"
          size="lg"
          variant="primary"
          @click="clickUpdate"
        >
          <span v-if="disabled">
            <b-spinner label="processing" />
          </span>
          <span v-else>{{ buttonText }}</span>
        </b-btn>
      </div>
    </b-form>
  </div>
  <div v-else>
    <Error403 />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as role from '../../utils/permissions';
import { validateEmail } from '../../utils/validation';

import Error403 from '../Error/403.vue';

import FormErrors from '../Form/FormErrors.vue';
import FormProgressBar from '../Form/FormProgressBar.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';
import FormSelect from '../Form/FormSelect.vue';

export default {
  name: 'ResellerProfile',
  components: {
    Error403,
    FormErrors,
    FormProgressBar,
    FormGroup,
    FormInput,
    FormSelect,
  },
  data() {
    return {
      pageName: 'resellerProfile',
      type: this.$route.params.type,
      state: null,
      invalidFeedback: '',
      disabled: false,
      email: '',
      token: '',
      publicKey: process.env.VUE_APP_RECURLY_PUBLIC_KEY,
    };
  },
  computed: {
    ...mapGetters([
      'errors',
      'firstName',
      'lastName',
      'countryOptions',
      'countryCodeOptions',
      'countryCode',
      'stateOptions',
      'accountCode',
      'paymentType',
      'achFullName',
      'achRoutingNumber',
      'achAccountNumber',
      'achAccountNumberConfirmation',
      'achAccountType',
      'ccCensored',
      'ccFirstName',
      'ccLastName',
      'expiryMonth',
      'expiryYear',
      'cvvCensored',
      'cardType',
    ]),
    description() {
      switch (this.type) {
        case 'name':
          return '';
        case 'email':
          return 'Update your email address below. We\'ll email you a link to verify your address.';
        case 'phone':
          return 'Update your mobile number below. We\'ll text you a code to verify your number.';
        case 'billing':
          return 'Update your payment method and billing address below.';
        default:
          return 'Update your profile below.';
      }
    },
    buttonText() {
      switch (this.type) {
        case 'name':
          return 'Update Name';
        case 'email':
          return 'Update Email';
        case 'phone':
          return 'Update Mobile Number';
        case 'billing':
          return 'Update Payment Profile';
        default:
          return 'Update Profile';
      }
    },
  },
  async beforeCreate() {
    if (this.$route.params.type === 'billing') await this.$store.dispatch('getUserBilling');
  },
  mounted() {
    if (this.errors.length) this.$store.commit('CLEAR_ERRORS');
  },
  methods: {
    hasPageAccess: role.hasPageAccess,
    hasComponentAccess: role.hasComponentAccess,
    progress() { return { full: true }; },
    paymentTypeOptions() {
      return [
        { value: '', text: 'Select', disabled: true },
        { value: 'CC', text: 'Credit Card' },
        { value: 'ACH', text: 'Bank Account' },
      ];
    },
    achAccountTypeOptions() {
      return [
        { value: 'checking', text: 'CHECKING' },
        { value: 'savings', text: 'SAVINGS' },
      ];
    },
    clickBack() {
      this.$router.push('/reseller/');
    },
    async clickUpdate() {
      this.state = '';
      this.invalidFeedback = '';
      this.disabled = true;
      switch (this.type) {
        case 'name':
          if (!this.firstName) {
            this.state = 'invalid';
            this.invalidFeedback = 'Error: First name is required. Enter your first name and try again.';
            this.disabled = false;
            break;
          } else {
            try {
              await this.$store.dispatch('updateName');
              // eslint-disable-next-line
              await confirm('Name has been successfully updated! You will now be redirected back to the profile page.');
              await this.$router.push(`/${this.$route.fullPath.split('/')[1]}/profile`);
            } catch (error) {
              this.state = 'invalid';
              this.invalidFeedback = `Error: ${error.errorMessage}`;
            }
            this.disabled = false;
            break;
          }
        case 'email':
          if (!validateEmail(this.email)) {
            this.state = 'invalid';
            this.invalidFeedback = 'Error: This email address is invalid';
            this.disabled = false;
            break;
          } else {
            try {
              await this.$store.dispatch('updateEmail', { email: this.email });
              this.$store.commit('UPDATE_EMAIL', this.email);
              await this.$router.push(`/${this.$route.fullPath.split('/')[1]}/${this.$route.fullPath.split('/')[2]}/verify/${this.type}`);
            } catch (error) {
              this.state = 'invalid';
              this.invalidFeedback = `Error: ${error.errorMessage}`;
            }
            this.disabled = false;
            break;
          }
        case 'phone':
          if (this.phoneNumber.length < 7) {
            this.state = 'invalid';
            this.invalidFeedback = 'Error: invalid number';
            this.disabled = false;
          } else {
            try {
              await this.$store.dispatch('userVerify');
              this.state = null;
              this.invalidFeedback = '';
              await this.$router.push(`/${this.$route.fullPath.split('/')[1]}/${this.$route.fullPath.split('/')[2]}/verify/${this.type}`);
            } catch (error) {
              console.error(error);
              this.state = 'invalid';
              this.invalidFeedback = `Error: ${error.errorMessage}`;
              this.disabled = false;
              break;
            }
          }
          break;
        case 'billing':
          try {
            await this.$store.dispatch('putUserBilling', this.$refs.token.value);
            await this.$store.dispatch('getUserBilling');
            // eslint-disable-next-line
            await confirm('Update success! You will now be redirected back.');
            this.$store.commit('SET_NEED_REFRESH');
            await this.$router.push('/reseller/');
          } catch (error) {
            console.error(error);
            this.state = 'invalid';
            this.invalidFeedback = `Error: ${error.errorMessage}`;
            // eslint-disable-next-line
            alert(this.invalidFeedback);
            this.disabled = false;
          }
          break;
        default:
          console.log('update profile');
          this.disabled = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/variables/_colors.scss';
@import '../../styles/variables/_sizes.scss';
@import '../../styles/theme/_default.scss';

#reseller-profile {
  .form-container {
    position: relative;
    text-align: left;
  }
  .form {
    padding: 80px 20px 0px 20px;
  }
  .back-button {
    position: absolute;
    top: 25px;
    left: 15px;
    display: inline;
    color: black;

  }
}
</style>
