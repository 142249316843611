<template>
  <b-input-group>
    <b-form-checkbox
      v-if="$attrs.type === 'checkbox'"
      :id="bindId"
      :ref="$attrs.ref"
      v-model="value"
      :aria-checked="value"
      :name="$attrs.name"
      :class="[{'invalid': $attrs.state === false || $attrs.state === 'invalid'}]"
      :state="$attrs.state"
      v-bind="$attrs"
    >
      {{ $attrs.label }}
    </b-form-checkbox>
    <b-form-input
      v-else
      :id="bindId"
      ref="input"
      v-model="value"
      :name="$attrs.name"
      :type="type"
      :class="[{'invalid': $attrs.state === false || $attrs.state === 'invalid'}]"
      :state="$attrs.state"
      v-bind="$attrs"
    />
    <b-input-group-append
      v-if="showPasswordToggle"
    >
      <span
        class="form-input-password-show-toggle"
        @click="toggleShowPassword"
      >
        {{ passwordToggleText }}
      </span>
    </b-input-group-append>
    <b-form-invalid-feedback :id="invalidFeedbackId">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
  </b-input-group>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    bindId: {
      type: String,
      required: true,
    },
    get: {
      type: String,
      required: true,
    },
    set: {
      type: String,
      required: true,
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: '',
    },
    invalidFeedbackId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      type: this.$attrs.type,
      showPasswordToggle: this.$attrs.type === 'password',
      passwordToggleText: 'SHOW',
    };
  },
  computed: {
    value: {
      get() {
        return this.$store.getters[this.get];
      },
      set(val) {
        this.$store.commit(this.set, val);
      },
    },
  },
  methods: {
    toggleShowPassword() {
      if (this.$refs.input.type === 'password') {
        this.type = 'text';
        this.passwordToggleText = 'HIDE';
      } else {
        this.type = 'password';
        this.passwordToggleText = 'SHOW';
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import '../../styles/objects/_form.scss';

.form-input-password-show-toggle {
  position: absolute !important;
  right: 15px;
  top: 10px;
  background-color: initial;
  cursor: pointer;
  z-index: 5;
}
.form-input {
  position: relative;
  .inline {
    width: unset !important;
  }
}

</style>
