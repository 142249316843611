<template>
  <b-input-group>
    <b-form-select
      v-if="options.length"
      :id="bindId"
      v-model="selected"
      :name="$attrs.name"
      :options="options"
      :state="$attrs.state"
      :class="[{'invalid': $attrs.state === false || $attrs.state === 'invalid'}]"
      v-bind="$attrs"
    />
    <b-form-select
      v-else-if="Object.keys(optionGroups).length"
      :id="bindId"
      v-model="selected"
      :name="$attrs.name"
      :state="$attrs.state"
      v-bind="$attrs"
      :class="[{'invalid': $attrs.state === false || $attrs.state === 'invalid'}]"
    >
      <option
        :value="''"
        disabled
      >
        Select
      </option>
      <optgroup
        v-for="(options, key) in optionGroups"
        :key="key"
        :label="key"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </optgroup>
    </b-form-select>
    <b-form-invalid-feedback :id="invalidFeedbackId">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
  </b-input-group>
</template>

<script>
export default {
  name: 'FormSelect',
  props: {
    bindId: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    optionGroups: {
      type: Object,
      required: false,
      default: () => {},
    },
    get: {
      type: String,
      required: true,
    },
    set: {
      type: String,
      required: true,
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: '',
    },
    invalidFeedbackId: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    selected: {
      get() {
        return this.$store.getters[this.get];
      },
      set(val) {
        this.$store.commit(this.set, val);
      },
    },
  },
};
</script>
