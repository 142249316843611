<template>
  <b-form-group
    :label="label"
    :label-for="labelFor"
    v-bind="$attrs"
    role="alert"
  >
    <label
      v-if="tip"
      class="form-group-tip"
    >
      {{ tip }}
    </label>
    <slot />
  </b-form-group>
</template>

<script>
export default {
  name: 'FormGroup',
  props: {
    for: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    tip: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    labelFor() {
      return `${this.for}`;
    },
    tipFor() {
      return `${this.for}-tip`;
    },
  },
};
</script>
