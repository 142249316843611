import store from '../store';
import permissions from '../data/role-permissions.json';

const roles = Object.keys(permissions);

function validRoles() {
  let valid = true;

  if (store.getters.role.length === 0) {
    valid = false;
  }

  store.getters.role.forEach((role) => {
    if (!roles.includes(role)) {
      valid = false;
    }
  });

  return valid;
}

export function hasPageAccess(page) {
  if (!validRoles()) return false;
  let valid = false;
  store.getters.role.forEach((role) => {
    if (permissions[role][page].length !== 0) {
      valid = true;
    }
  });

  return valid;
}
export function hasComponentAccess(page, component) {
  if (!validRoles()) return false;
  let valid = false;
  store.getters.role.forEach((role) => {
    if (permissions[role][page].includes('full') || permissions[role][page].includes(component)) {
      valid = true;
    }
  });

  return valid;
}

export const role = {
  hasPageAccess,
  hasComponentAccess,
};
