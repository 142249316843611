<template>
  <div
    id="progress-bar"
    class="d-flex justify-content-between"
  >
    <div
      v-if="progress.full"
      class="progress-bar-full active"
    />
    <div
      v-if="!progress.full"
      :class="['progress-bar-partial-1', {'active': progress.step1}]"
    />
    <div
      v-if="!progress.full"
      :class="['progress-bar-partial-2', {'active': progress.step2}]"
    />
    <div
      v-if="!progress.full"
      :class="['progress-bar-partial-3', {'active': progress.step3}]"
    />
  </div>
</template>

<script>
export default {
  name: 'FormProgressBar',
  props: {
    progress: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables/_colors.scss';

#progress-bar {
  height: auto;
  // width: inherit;
  & div {
    height: 8px;
    width: 32.5%;
    display: inline-block;
    background: -webkit-linear-gradient(left, $aira-color-gradient-left, $aira-color-gradient-right);
    background: -o-linear-gradient(left, $aira-color-gradient-left, $aira-color-gradient-right);
    background: -webkit-gradient(linear, left top, right top, from($aira-color-gradient-left), to($aira-color-gradient-right));
    background: linear-gradient(to right, $aira-color-gradient-left, $aira-color-gradient-right);
    opacity: 0.13;
  }
  .active {
    opacity: 1;
  }
  .progress-bar-full {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .progress-bar-partial-1 {
    border-top-left-radius: 4px;
  }
  .progress-bar-partial-3 {
    border-top-right-radius: 4px;
  }
}
</style>
