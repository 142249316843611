<template>
  <div
    v-show="errors.length"
    id="signup-form-errors"
    role="alert"
  >
    <p ref="errormessage">
      <b>There were errors in your submission:</b>
    </p>
    <ul aria-labelledby="errors">
      <li
        v-for="(error, index) in errors"
        :key="index"
        v-html="error"
      />
    </ul>
    <p><b>Please fix these errors and try again.</b></p>
  </div>
</template>

<script>
// TODO use bootstrap vue alert component
import { mapGetters } from 'vuex';

export default {
  name: 'FormErrors',
  computed: {
    ...mapGetters([
      'errors',
    ]),
  },
  watch: {
    errors() {
      if (this.errors.length) window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang='scss'>
@import '../../styles/variables/_colors.scss';

#signup-form-errors {
  font-size: 14px;
  position: relative;
  top: 25px;
  line-height: 1;
  margin: 0 20px 10px 20px;
  padding: 10px 15px;
  background-color: $aira-color-errors-bg;
  border: 2px solid $aira-color-errors-border;
  border-radius: 2px;
  text-align: left;
  ul {
    margin: 5px 0;
  }
  li {
    margin-left: 30px;
    font-size: 14px;
    line-height: 1.5;
  }
}
</style>
